var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notice-board-page"},[_c('v-row',{staticClass:"my-2",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('intract-smart-list',{ref:"notice-posts-smart-list",attrs:{"endpoint":_vm.noticePostsEndpoint,"paginated":"","disable-search":"","filter-fields":_vm.showFilterOptions ? _vm.filterFields : null},scopedSlots:_vm._u([{key:"custom-filter--dateRange",fn:function(){return [_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"solo":"","clearable":"","value":_vm.formattedDateRangeString,"label":"Filter by Date","prepend-inner-icon":"mdi-calendar","readonly":"","hide-details":"auto"},on:{"click:clear":function($event){_vm.date = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[(_vm.date && _vm.date.length == 1)?_c('span',{staticClass:"text-caption red--text"},[_vm._v("Please select End Date as well")]):_vm._e()]),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","disabled":_vm.date && _vm.date.length == 1,"color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)],1)]},proxy:true},{key:"list-item",fn:function(ref){
var post = ref.item;
return [_c('v-card',{staticClass:"mx-2 my-3",on:{"click":function($event){return _vm.$router.push({
                name: 'ViewBoardPost',
                params: { postId: post.id },
              })}}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.currentInstitution.logo}})],1),_c('v-list-item-content',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm.currentInstitution.name)+" ")]),_c('p',{staticClass:"my-0 caption grey--text"},[_vm._v(" "+_vm._s(_vm.moment(post.created).format("hh:mmA, Do MMM"))+" ")])],1),_c('v-card-text',{staticClass:"py-1 pb-2"},[_c('div',{staticClass:"black--text subtitle-1 content-description"},[_vm._v(" "+_vm._s(_vm._f("truncate")(post.description,150, "..."))+" ")]),(post.board_images.length)?_c('v-img',{attrs:{"src":post.board_images[0].image}}):_vm._e()],1),(
                post.board_images.length ||
                post.board_documents.length ||
                post.is_draft
              )?_c('v-card-actions',{staticClass:"pt-0 ml-1"},[(post.is_draft && post.scheduled)?_c('v-chip',{attrs:{"small":"","color":"orange lighten-2 mr-1"}},[_vm._v("Scheduled")]):_vm._e(),(
                  post.board_images.length && post.board_images.length > 1
                )?_c('v-chip',{attrs:{"small":"","color":"light-blue lighten-4 mr-1"}},[_vm._v("+"+_vm._s(post.board_images.length - 1)+" Images")]):_vm._e(),(post.board_documents.length)?_c('v-chip',{attrs:{"small":"","color":"teal lighten-4"}},[_vm._v(_vm._s(post.board_documents.length)+" Document(s)")]):_vm._e()],1):_vm._e()],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }