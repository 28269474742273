<template>
  <div>
    <div v-if="!currentUser.is_student">
      <v-tabs v-model="activeTab" fixed-tabs>
        <v-tab class="non-focused">
          <strong>For All</strong>
        </v-tab>
        <v-tab class="non-focused" v-if="!currentUser.is_student">
          <strong>For Staff</strong>
        </v-tab>
        <v-tab-item>
          <board-posts
            :showFilterOptions="showFilterOptions"
            :for_staff="false"
            ref="staffBoardPosts"
          ></board-posts>
        </v-tab-item>
        <v-tab-item>
          <board-posts
            :showFilterOptions="showFilterOptions"
            :for_staff="true"
            ref="allBoardPosts"
          ></board-posts>
        </v-tab-item>
      </v-tabs>
    </div>

    <div v-else>
      <board-posts
        :showFilterOptions="showFilterOptions"
        :for_staff="false"
      ></board-posts>
    </div>

    <v-fab-transition v-if="currentUser.is_admin && !isLoading">
      <v-btn
        color="primary"
        @click="noticeCreateSheet.visible = true"
        dark
        bottom
        right
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <intract-create-edit-sheet
      persistent
      v-if="currentUser.is_admin"
      title="Send Notice"
      description="This will be notified to all the students and the staff. Notice only for staff can also be created."
      :visible="noticeCreateSheet.visible"
      @close="noticeCreateSheet.visible = false"
      :fields="formFields"
      enable-media
      :endpoint="endpoints.boardPosts"
      :edit-id="noticeCreateSheet.editId"
      :data-object="noticeCreateSheet.obj"
      :create-success-message="noticeCreateSheet.createSuccessMessage"
      @objectCreated="noticeCreated"
      @updateObject="(obj) => (noticeCreateSheet.obj = obj)"
    >
      <!-- schedule for later button slot -->
      <template
        v-slot:after-submit-button
        v-if="!noticeCreateSheet.obj.scheduled"
      >
        <v-dialog v-model="scheduleDialog.visible" width="500" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="blue" outlined block dark v-bind="attrs" v-on="on">
              Schedule for Later
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Schedule for later
              <v-btn
                icon
                absolute
                top
                right
                @click="
                  scheduleDialog.visible = false;
                  scheduleDialog.datetime = null;
                "
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-text-field
                class="mt-4 mb-0"
                hide-details="auto"
                dense
                outlined
                type="datetime-local"
                label="Schedule Date & Time"
                v-model="scheduleDialog.datetime"
              >
              </v-text-field>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                block
                :disabled="!scheduleDialog.datetime"
                @click="
                  noticeCreateSheet.obj.scheduled = scheduleDialog.datetime;
                  scheduleDialog.visible = false;
                  scheduleDialog.datetime = null;
                "
              >
                Schedule
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <!-- scheduled for information -->
      <template v-slot:custom-field--scheduled="{ obj }">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">
              Scheduled for
              <strong class="orange--text">{{
                moment(obj.scheduled).format("LLL")
              }}</strong>
            </v-list-item-title>
            <v-list-item-subtitle>
              Will be published at the specified time.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon @click="obj.scheduled = null">mdi-close</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
    </intract-create-edit-sheet>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Mixins from "@utils/mixins";
import moment from "moment";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import EventBus from "@utils/event_bus";
import BoardPosts from "@components/board/BoardPosts.vue";

export default {
  name: "NoticeBoard",

  data() {
    return {
      activeTab: 0,
      moment: moment,
      showFilterOptions: false,
      scheduleDialog: {
        visible: false,
        datetime: null,
      },
      noticeCreateSheet: {
        visible: false,
        createSuccessMessage: "Notice sent successfully!",
        editId: null,
        obj: {
          is_alert: false,
          description: "",
          institution: null,
          recipients: [],
          allow_replies: false,
          for_staff: false,
          scheduled: null,
        },
      },
    };
  },
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions],
  components: {
    IntractCreateEditSheet,
    BoardPosts,
  },
  computed: {
    appBarOptions() {
      return {
        iconButton: {
          icon: "mdi-filter-variant",
          action: () => {
            // toggle filter options
            this.showFilterOptions = !this.showFilterOptions;
          },
        },
      };
    },

    ...mapGetters([
      "isLoading",
      "currentUser",
      "accessToken",
      "currentInstitution",
    ]),

    formFields() {
      return {
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        allow_replies: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "Allow Replies",
          helper:
            "Will allow students to add replies to this notice. Replies will also be visible to other students.",
          required: false,
          md: 12,
        },
        for_staff: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "For staff only",
          helper:
            "This will keep the notice visible to staff and hidden from students.",
          required: false,
          md: 12,
        },
        scheduled: {
          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
          hide: this.noticeCreateSheet.obj.scheduled ? false : true,
        },
        board_images: {
          fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
          multiple: true,
          label: "Images",
          helper: "JPG, PNG, GIF, etc",
        },
        board_documents: {
          fieldType: CreateEditFieldTypes.FILEUPLOAD,
          multiple: true,
          label: "Documents",
          helper: "PDF, Video, Audio, Docs (Upto 30MB)",
        },
      };
    },
  },

  methods: {
    ...mapActions(["showSnackbar", "setLoading"]),

    async noticeCreated(data) {
      if(data.for_staff)
        EventBus.$emit("refreshStaffBoardPosts");
      else
        EventBus.$emit("refreshAllBoardPosts");
      this.$router.push({ name: "ViewBoardPost", params: { postId: data.id } });
    },
  },

  beforeRouteLeave(to, from, next) {
    if (to.name == "Home") {
      console.log("destroying");
      // this.$destroy();
      EventBus.$emit("keep_alive__destroy_view", "NoticeBoard");
    }
    next();
  },

  created() {
    this.noticeCreateSheet.obj.recipients.push(this.currentUser.id);
    this.noticeCreateSheet.obj.user = this.currentUser.id;
    this.noticeCreateSheet.obj.institution = this.currentInstitution.id;
  },
};
</script>
<style scoped>
.fab-transition-enter {
  transition-delay: 2s;
}
</style>